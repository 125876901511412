import { useQuery } from '@tanstack/react-query';
import { postDataBodyQuery } from 'api/getDataBodyQuery';
import { IsSuccess, SpinnerLoading } from 'components/common';
import { PrimaryButton } from 'components/common/button/PrimaryButton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { subjectTeacherState } from 'recoil/subject/subjectTeacherState';

export function SubjectDetailTeacher() {
  const navigate = useNavigate();
  const subjectTeacherData = useRecoilValue(subjectTeacherState);
  const [activeIndex, setActiveIndex] = useState(0);

  console.log('subjectTeacherData', subjectTeacherData);
  const [filterIndex, setFilterIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const { data: subjectUserList, isLoading: subjectUserListIsLoading } = useQuery({
    queryKey: [
      'subjectUserList',
      subjectTeacherData.assignment,
      subjectTeacherData,
      subjectTeacherData.courseId,
      subjectTeacherData.moduleId,
    ],
    queryFn: async () =>
      postDataBodyQuery(
        `/course/${subjectTeacherData.courseId}/module/${subjectTeacherData.moduleId}/assignment/${subjectTeacherData.assignment}`,
        { studentLmsUserIds: subjectTeacherData.studentLmsUserIds }
      ),

    enabled:
      !!subjectTeacherData.courseId &&
      !!subjectTeacherData.moduleId &&
      !!subjectTeacherData.assignment &&
      !!subjectTeacherData.studentLmsUserIds,
  });

  console.log('subjectUserList', subjectUserList);
  useEffect(() => {
    if (subjectUserList) return setFilterData(subjectUserList?.data);
  }, [subjectUserList]);

  const handleBtnClick = (index, type) => {
    setFilterIndex(index);

    if (type === 'all') {
      setActiveIndex(0);
      setFilterData(subjectUserList?.data);
    }
    if (type === 'completed') {
      const completedItems = subjectUserList?.data?.filter((el) => el.assignmentState === 'COMPLETE');
      setActiveIndex(1);
      setFilterData(completedItems);
    }
    if (type === 'uncompleted') {
      const uncompletedItems = subjectUserList?.data?.filter((el) => el.assignmentState === 'UNCOMPLETE');
      setActiveIndex(2);
      setFilterData(uncompletedItems);
    }
  };

  return (
    <>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold fz-24'>{subjectTeacherData.titleaa}</h3>

                  <PrimaryButton onClick={() => navigate('/subject', { replace: true })}>목록</PrimaryButton>
                </div>
              </div>

              {
                // subjectMainData
                <>
                  {/* 셀렉트 information */}

                  {/* 아래 카드 */}
                  <div className='card p-20'>
                    <div className='mb-20 d-flex justify-content-between align-items-center'>
                      {/* //TODO 코드 리펙토링해야함 */}
                      <ul className='d-flex '>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(0, 'all');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 0 && 'btn-filter-active'}`}
                          >
                            전체 &#40;
                            {subjectUserListIsLoading && 0}
                            {subjectUserList?.data.length}
                            &#41;
                          </button>
                        </li>
                        <li className='me-3'>
                          <button
                            onClick={() => {
                              handleBtnClick(1, 'completed');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 1 && 'btn-filter-active'}`}
                          >
                            완료 &#40;
                            {subjectUserListIsLoading && 0}
                            {subjectUserList?.data.filter((el) => el.assignmentState !== 'UNCOMPLETE').length}&#41;
                          </button>
                        </li>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(2, 'uncompleted');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 2 && 'btn-filter-active'}`}
                          >
                            미완료 &#40;
                            {subjectUserListIsLoading && 0}
                            {subjectUserList?.data.filter((el) => el.assignmentState === 'UNCOMPLETE').length}
                            &#41;
                          </button>
                        </li>
                      </ul>
                      <input
                        type='text'
                        className='border rounded py-2 px-3 w-50'
                        name=''
                        id=''
                        placeholder='이름 검색'
                      />
                    </div>

                    <Table>
                      <thead className='fw-bold border-bottom'>
                        <tr>
                          <th className='py-3' style={{ width: '7.07%' }}>
                            번호
                          </th>
                          <th className='py-3 text-start' style={{ width: '18.57%' }}>
                            학번
                          </th>
                          <th className='py-3 text-start' style={{ width: '32.22%' }}>
                            학생
                          </th>
                          <th className='py-3' style={{ width: '11.79%' }}>
                            상태
                          </th>
                          <th className='py-3 text-start' style={{ width: '18.57%' }}>
                            제출 완료 일자
                          </th>
                          <th className='py-3 text-start' style={{ width: '11.79%' }}>
                            점수
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterData?.map((item, index) => (
                          <tr className='border-bottom'>
                            <td className='py-3 text-center' style={{ width: '7.07%' }}>
                              {index + 1}
                            </td>
                            <td className='py-3' style={{ width: '18.57%' }}>
                              {item.userNumber}
                            </td>
                            <td className='py-3' style={{ width: '32.22%' }}>
                              {item.name}
                            </td>
                            <td className='py-3 text-center' style={{ width: '11.79%' }}>
                              <div className='w-100 h-100 d-flex justify-content-center'>
                                <IsSuccess completed={item.assignmentState !== 'UNCOMPLETE'} />
                              </div>
                            </td>
                            <td className='py-3' style={{ width: '18.57%' }}>
                              {item.submittedAt ? moment(item.submittedAt).format('YY-MM-DD') : '-'}
                            </td>
                            <td className='py-3' style={{ width: '11.79%' }}>
                              {isNaN(item.score) ? '-' : item.score} /{' '}
                              {isNaN(item.possiblePoint) ? '-' : item.possiblePoint}
                            </td>
                          </tr>
                        ))}

                        {subjectUserListIsLoading && (
                          <tr className='position-relative'>
                            <td className='py-5'>
                              <SpinnerLoading />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
