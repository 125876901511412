import { getSessionStorage, setSessionStorage } from 'assets/constant';
import axios from 'axios';
import { setCookie } from 'util/cookie';
import { getNewRefreshToken } from './refresh';

let isTokenRefreshing = false;
let failedRequestsQueue = [];

export const getDataQuery = async (url) => {
  const access = getSessionStorage();
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER}${url}`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    // serverErrorHandler(response);

    const checkT007 = response.data?.errorCode === 'T007';
    const checkExpired = response.data?.errorMessage === 'expired token';

    if (checkT007 && checkExpired) {
      if (!isTokenRefreshing) {
        // 리프레시 중이라면?
        isTokenRefreshing = true;
        const { data } = await getNewRefreshToken();
        const { accessToken, refreshToken } = data;
        setSessionStorage(accessToken);
        setCookie('refreshToken', refreshToken);
        isTokenRefreshing = false;

        // 재시도 실패한 요청
        failedRequestsQueue.forEach((callback) => callback(accessToken));
        failedRequestsQueue = [];
      } else {
        // 새로고침된 토큰 대기열 추가
        const retryRequest = new Promise((resolve, reject) => {
          failedRequestsQueue.push((newToken) => {
            axios
              .get(`${process.env.REACT_APP_SERVER}${url}`, {
                headers: {
                  Authorization: `Bearer ${newToken}`,
                },
              })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        });

        return retryRequest;
      }
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
