import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export const useGetAdminSubjectQuery = (aa) => {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const checkAdmin = decoded.role === 'ADMIN';

  const response = useQueries({
    queries: [
      {
        // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
        queryKey: ['adminSubjectLecture', decoded.sub],
        queryFn: () => getDataQuery('/teach/home/sub-accounts'),
        enabled: checkAdmin,
      },
      {
        // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
        queryKey: ['adminSubjectCompletedStudent', decoded.sub, aa],
        queryFn: () => getDataQuery(`/teach/courses/${aa}/modules/completed-count`),
        enabled: checkAdmin && !!aa,
      },
      {
        // 교수자 - [학습현황] 과목의 모듈별 완료한 학생 수
        queryKey: ['adminSubjectCompletedStudent', decoded.sub, aa],
        queryFn: () => getDataQuery(`/teach/courses/${aa}/modules/completed-count`),
        enabled: checkAdmin && !!aa,
      },
      {
        // 교수자 - [학습현황] 과목을 수강중인 학생들의 아이디 목록
        queryKey: ['adminSubjectStudentList', decoded.sub, aa],
        queryFn: () => getDataQuery(`/teach/courses/${aa}/students`),
        enabled: checkAdmin && !!aa,
      },
      {
        // 관리자 - 해당 학과의 과목 리스트
        queryKey: ['adminSubjectLectureList', decoded.sub, aa],
        queryFn: () => getDataQuery(`/teach/home/account/${aa}/courses`),
        enabled: checkAdmin && !!aa,
      },
    ],
  });

  const [adminSubjectDepartment] = response?.map((query) => query);
  const [adminSubjectDepartmentData, aab] = response?.map((query) => query?.data?.data);

  // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
  const { isLoading: adminSubjectDepartmentIsLoading, isError: adminSubjectDepartmentIsError } = adminSubjectDepartment;

  return {
    // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
    adminSubjectDepartmentData,
    adminSubjectDepartmentIsLoading,
    adminSubjectDepartmentIsError,
    aab,
  };
};
